import { createRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectMenuWithOffers } from 'store/selectors/menuSelectors';
import { useWindowDimensions } from 'hooks/menu';

const useMenu = () => {
  const [opacity, setOpacity] = useState<number>(1);
  const { pageHeight } = useWindowDimensions();
  const { t } = useTranslation();
  const observerMargin = Math.floor(pageHeight / 2);
  const menuWithOffers = useAppSelector(selectMenuWithOffers(t('promo')));
  const [activeCategory, setActiveCategory] = useState<number | string>(
    menuWithOffers[0]?.id
  );

  const rootMargin = useMemo(() => {
    return `-${
      pageHeight % 2 === 0 ? observerMargin - 1 : observerMargin
    }px 0px -${observerMargin}px 0px`;
  }, [observerMargin]);

  const categoryRefs = menuWithOffers.reduce((refsObj: any, category) => {
    refsObj[category.name] = createRef<HTMLDivElement>();
    return refsObj;
  }, {});

  const handleScrollTo = (name: string) => () => {
    setOpacity(0);
    setTimeout(() => {
      setOpacity(1);
    }, 400);
    const element = categoryRefs[name].current;
    const headerOffset = 190;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    setTimeout(() => {
      window.scrollTo({
        top: offsetPosition,
      });
    }, 300);
  };

  const handleSetActiveCategory = (category: number | string) => {
    setActiveCategory(category);
  };

  return {
    opacity,
    activeCategory,
    menuWithOffers,
    categoryRefs,
    rootMargin,
    handleScrollTo,
    handleSetActiveCategory,
  };
};

export default useMenu;
