import axios from 'axios';
import React, { PropsWithChildren } from 'react';

/**
 * Catches errors within component tree and reports it to the server for future analysis.
 * https://reactjs.org/docs/error-boundaries.html
 * Use redux connect API to connect store to the class component
 */
class ErrorBoundary extends React.Component<PropsWithChildren<unknown>> {
  componentDidCatch(error: any, errorInfo: any) {
    // No need extra error handlers in development or testing env.
    if (process.env.NODE_ENV !== 'production') return;

    const body = {
      error,
      location: window.location.href,
    };

    // Post error report without waiting for the response.
    // Uncomment when backend is ready.
    // axios.post('/report-error', body);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
