import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuCategory } from 'types/Menu';
import { getMenu } from 'store/reducers/MenuSlice/ActionCreators';

interface MenuState {
  menu: MenuCategory[];
  isLoading: boolean;
  error: string;
}

const initialState: MenuState = {
  menu: [],
  isLoading: false,
  error: '',
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {},
  extraReducers: {
    [getMenu.fulfilled.type]: (
      state,
      action: PayloadAction<MenuCategory[]>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.menu = action.payload;
    },
    [getMenu.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getMenu.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default menuSlice.reducer;
