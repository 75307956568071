import React, { FC, useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { PromotionModal } from 'components/modals';

interface CardProps {
  image: string;
  desktopImage: string;
  title: string;
  description: string;
  date: string;
}

const Card: FC<CardProps> = ({
  image,
  desktopImage,
  title,
  description,
  date,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [isModalOpen]);

  return (
    <>
      <Box
        sx={{
          height: { xs: '130px', sm: '177px' },
          borderRadius: '0px',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
        onClick={handleOpenModal}
      >
        <Box
          sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
          component="img"
          src={image}
        />
      </Box>

      <PromotionModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        src={desktopImage}
        title={title}
        description={description}
        date={date}
      />
    </>
  );
};

export default Card;
