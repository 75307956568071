import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectMenuWithOffers } from 'store/selectors/menuSelectors';

const useActiveMenuObserver = (
  ref: MutableRefObject<Element | null>,
  activeCategory: number | string,
  handleSetActiveCategory: (arg: number | string) => void,
  options: IntersectionObserverInit = {}
) => {
  const [element, setElement] = useState<Element | null>(null);
  const observer = useRef<null | IntersectionObserver>(null);
  const menuWithOffers = useAppSelector(selectMenuWithOffers(''));

  const cleanObserver = () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };

  useEffect(() => {
    if (ref) {
      setElement(ref.current);
    }
  }, [ref]);

  useEffect(() => {
    if (!element) return;
    cleanObserver();
    const position = window.pageYOffset;
    // eslint-disable-next-line no-multi-assign
    const ob = (observer.current = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting;
        const firstCategory = menuWithOffers
          .sort((prev, next) => prev.orderBy - next.orderBy)[0]
          .id.toString();
        if (
          Number(entry.target.id) !== activeCategory &&
          isElementIntersecting
        ) {
          handleSetActiveCategory(entry.target.id);
        }
        if (position <= 0) {
          handleSetActiveCategory(firstCategory);
        }
      },
      { ...options }
    ));

    ob.observe(element);

    // eslint-disable-next-line consistent-return
    return () => {
      cleanObserver();
    };
  }, [element, options, activeCategory]);

  return activeCategory;
};

export default useActiveMenuObserver;
